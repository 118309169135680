import {
    Box,
    Typography,
    CircularProgress,
    IconButton,
    Hidden,
    Tooltip,
    Card,
    Fab,
} from 'components/mui';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import {CachedSignal, SignalType} from 'src/types/graphql-types';

import {useDeleteCachedSignalMutation} from 'components/ui/pages/signals/hooks/useDeleteCachedSignalMutation';
import {SignalComponentUi} from 'components/ui/pages/signals/explore/SignalCardElements';
import {SignalComponentCompanyCountEstimate} from 'components/ui/pages/signals/components/SignalComponentCompanyCountEstimate';
import {EXPLORE_SIGNALS_URL} from 'components/util/routeConstants';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useRouter} from 'next/router';

const signalTypeLabel = {
    [SignalType.CompanySummary]: 'Company Summary',
    [SignalType.Employee]: 'Employee',
    [SignalType.Firmographic]: 'Firmographic',
    [SignalType.JobPosting]: 'Job Posting',
    [SignalType.Mixed]: 'Mixed',
    [SignalType.Technographic]: 'Technographic',
    [SignalType.News]: 'News',
};

const signalTypeIcon = {
    [SignalType.CompanySummary]: BusinessIcon,
    [SignalType.Employee]: PersonIcon,
    [SignalType.Firmographic]: BusinessIcon,
    [SignalType.JobPosting]: WorkIcon,
    [SignalType.Mixed]: QuestionMarkIcon,
    [SignalType.Technographic]: DeveloperBoardIcon,
    [SignalType.News]: NewspaperIcon,
};

const RecommendedSignalCard = ({
    cachedSignal,
    clientOrganizationId,
    signalCacheIndex,
    setSessionCachedSignal,
}: Readonly<{
    cachedSignal: CachedSignal;
    clientOrganizationId: string;
    signalCacheIndex: number;
    setSessionCachedSignal: Dispatch<SetStateAction<CachedSignal>>;
}>) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const router = useRouter();
    const {name, description, type, conditions} = cachedSignal.signal;
    const {deleteCachedSignalMutation, loading: deleteCachedSignalLoading} =
        useDeleteCachedSignalMutation({
            clientOrganizationId,
            cachedSignalId: cachedSignal.id,
        });

    useEffect(() => {
        if (deleteCachedSignalLoading) {
            setIsDeleting(true);
        }
    }, [deleteCachedSignalLoading]);

    const condition = conditions[0];
    const signalComponent = condition.components[0];
    const signalComponentCompanyCountEstimate =
        signalComponent?.companyCountEstimate;

    const SignalTypeIcon = signalTypeIcon[type];

    const pageHref = `${EXPLORE_SIGNALS_URL}/${signalCacheIndex.toString()}`;

    return (
        <Card
            key={name}
            sx={{
                m: 1,
                transition: 'all 0.3s ease-in-out',
            }}
            className={`color-${type as string}`}
            elevation={1}
        >
            <Box p={1} m={1}>
                {isDeleting ? (
                    <Box data-testid="signal-card-loading">
                        <CircularProgress size={24} />
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        minHeight="300px"
                        data-testid="signal-card"
                    >
                        <Box>
                            <Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={1}
                                >
                                    <Box width="90%">
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {name}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="flex-start"
                                        sx={{height: '50px'}}
                                        mt={-1}
                                        mr={-1}
                                    >
                                        {' '}
                                        <Tooltip title="Remove this signal and generate a new one">
                                            <IconButton
                                                data-testid="delete-button"
                                                onClick={() => {
                                                    deleteCachedSignalMutation().catch(
                                                        () => null
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <SignalComponentCompanyCountEstimate
                                    signalComponentCompanyCountEstimate={
                                        signalComponentCompanyCountEstimate
                                    }
                                />
                                <Typography mt={1}>{description}</Typography>
                            </Box>
                            <Box mt={2}>
                                <SignalComponentUi
                                    component={signalComponent}
                                />
                            </Box>
                        </Box>
                        <Box
                            component="div"
                            mt={2}
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                        >
                            <Box display="flex" alignItems="center">
                                <Box mr={1} display="flex" alignItems="center">
                                    <SignalTypeIcon color="primary" />
                                </Box>
                                <Typography
                                    variant="body2"
                                    component="span"
                                    color="primary"
                                >
                                    {signalTypeLabel[type]} Signal{' '}
                                </Typography>
                            </Box>
                            <Hidden mdDown>
                                <Fab
                                    onClick={() => {
                                        setSessionCachedSignal(cachedSignal);
                                        router.push(pageHref).catch(() => null);
                                    }}
                                    data-testid="expand-button"
                                    color="primary"
                                    variant="extended"
                                >
                                    <>
                                        Explore
                                        <OpenInNewIcon sx={{ml: 1}} />
                                    </>
                                </Fab>
                            </Hidden>
                        </Box>
                    </Box>
                )}
            </Box>
        </Card>
    );
};

export {RecommendedSignalCard};
